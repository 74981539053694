import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import {
  SassyText,
  Title,
  Section,
  Text,
  SectionWrapper,
} from '../components/core';

import BuildingArt from '../assets/images/building.svg';
import StockArt from '../assets/images/stock.svg';
import MachineryArt from '../assets/images/machinery.svg';
import AssetsArt from '../assets/images/assets.svg';

import Lightning from '../assets/images/lightning.svg';
import Earthquake from '../assets/images/earthquake.svg';
import Riots from '../assets/images/riots.svg';
import Missile from '../assets/images/missile.svg';
import Theft from '../assets/images/theft.svg';
import ForrestFire from '../assets/images/forrest_fire.svg';
import Storm from '../assets/images/storm.svg';
import Collision from '../assets/images/collision.svg';
import WaterGun from '../assets/images/watergun.svg';
import Leakage from '../assets/images/leakage.svg';
import useHindiLangFont from '../hooks/useHindiLangFont';

const Coverage = ({ type, enableTranslations=true }) => {
  let {hindiBold, hindiBlack, hindiRegular} = useHindiLangFont(enableTranslations)
  
  const {t} = useTranslation('coverageSection')
  return (
    <Section background="#FFF7F1">
      <SectionWrapper>
        <Title className={hindiBlack}>
          <Trans i18nKey="Title">
            What does <SassyText> {type} </SassyText> cover?
          </Trans>
        </Title>
        <Left>
          <Text
            fontWeight="bold"
            fontSize="24px"
            mfontSize="20px"
            style={{ marginTop: '45px' }}
          >
            <SassyText className={hindiBold}>{t('Assets')}</SassyText>
          </Text>
          <Container>
            <Column>
              <Row>
                <BuildingArt className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Buildings&Structures.Title')}
                </Text>
              </Row>
              <ul>
                <li className={hindiRegular}>{t('Buildings&Structures.Garages')}</li>
                <li className={hindiRegular}>{t('Buildings&Structures.Fences')}</li>
                <li className={hindiRegular}>{t('Buildings&Structures.Lifts')}</li>
                <li className={hindiRegular}>{t('Buildings&Structures.SecuritySystems')}</li>
              </ul>
            </Column>
            <Column>
              <Row>
                <MachineryArt className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('PlantMachinery.Title')}
                </Text>
              </Row>
              <ul>
                <li className={hindiRegular}>{t('PlantMachinery.Computers')}</li>
                <li className={hindiRegular}>{t('PlantMachinery.SystemSoftware')}</li>
                <li className={hindiRegular}>{t('PlantMachinery.Pipes')}</li>
                <li className={hindiRegular}>{t('PlantMachinery.Cables')}</li>
              </ul>
            </Column>
          </Container>
          <Container>
            <Column>
              <Row>
                <StockArt className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('StockOfGoods.Title')}
                </Text>
              </Row>
              <ul>
                <li className={hindiRegular}>{t('StockOfGoods.FinishedGoods')}</li>
                <li className={hindiRegular}>{t('StockOfGoods.StockInProgress')}</li>
                <li className={hindiRegular}>{t('StockOfGoods.RawMaterials')}</li>
                <li className={hindiRegular}>{t('StockOfGoods.StockInTrust')}</li>
              </ul>
            </Column>

            <Column>
              <Row>
                <AssetsArt className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('OtherAssets.Title')}
                </Text>
              </Row>
              <ul>
                <li className={hindiRegular}>{t('OtherAssets.Furniture')}</li>
                <li className={hindiRegular}>{t('OtherAssets.Fixtures')}</li>
                <li className={hindiRegular}>{t('OtherAssets.ElectronicEquipment')}</li>
                <li className={hindiRegular}>{t('OtherAssets.CanteenEquipment')}</li>
              </ul>
            </Column>
          </Container>
          <Text
            fontWeight="bold"
            fontSize="24px"
            mfontSize="20px"
            style={{ marginTop: '45px' }}
          >
            <SassyText type="secondary" className={hindiBold}>{t('Risks.Title')}</SassyText>
          </Text>
          <RiskRow>
            <Column>
              <Container row>
                <Lightning className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.FireLightning')}
                </Text>
              </Container>
              <Container row>
                <Earthquake className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.EathquakeLandslide')}
                </Text>
              </Container>
              <Container row>
                <Riots className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.RiotsStrikes')}
                </Text>
              </Container>
              <Container row>
                <Missile className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.MissileTesting')}
                </Text>
              </Container>
              <Container row>
                <Theft className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.Theft')}
                </Text>
              </Container>
            </Column>
            <Column>
              <Container row>
                <ForrestFire className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.ForestFire')}
                </Text>
              </Container>
              <Container row>
                <Storm className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.Storm')}
                </Text>
              </Container>
              <Container row>
                <Collision className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.ImpactCollision')}
                </Text>
              </Container>
              <Container row>
                <WaterGun className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.Terrorism')}
                </Text>
              </Container>
              <Container row>
                <Leakage className="icon" />
                <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                  {t('Risks.Leakages')}
                </Text>
              </Container>
            </Column>
          </RiskRow>
        </Left>
      </SectionWrapper>
    </Section>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Left = styled.div`
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const RiskRow = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 20px 0px;

  li {
    color: #999999;
    margin-left: 24px;
  }

  ul {
    line-height: 30px;
    margin-left: 70px;
    margin-bottom: 20px;
  }

  .icon {
    margin: 5px 35px 0px 0px;
  }
  .space-left {
    padding-left: 35px;
  }

  .space-right {
    padding-right: 35px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: ${({ row }) => `${row ? 'row' : 'column'}`};
  }
`;

export default Coverage;
